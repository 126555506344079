.main-container-GerenciarColetas {
    display: flex;
    width: 100%;
}

.close-bnt-GerenciarColetas {
    position: absolute;
    right: 1px;
    top: 0px;
    display: flex;
    justify-content: start;
    width: 100%;
}

.close-bnt-TabelaQuadra-GerenciarColetas {
    position: absolute;
    display: flex;
    justify-content: end;
    width: 100%;
}

.container-GerenciarColetas {
    flex: 1;
    flex-direction: column;
}

.conteudo-GerenciarColetas {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.input-GerenciarColetas {
    width: 100%;
}

.conteudo-lista-GerenciarColetas {
    background-color: rgb(255, 255, 255);
    margin: 5px;
    border-radius: 10px;
    box-shadow: 0 5px rgba(216, 214, 214, 0.811);
    padding: 30px;
}

.barra-direita-GerenciarColetas {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 10px;
    margin-top: 18px;
    position: fixed; 
    right: 5px; 
  
}

.btn-GerenciarColetas {
    background-color: rgb(19, 100, 181);
    border: none;
    padding: 15px;
    border-radius: 25px;
    font-size: large;
    color: white;
}

.btn-GerenciarColetas:hover {
    background-color: rgb(19, 51, 181);
    cursor: pointer;
}
.linha-GerenciarColetas {
    height: 2.5px;
    background-color: #1976D2;
    width: 100%;
}
.desabilitado-GerenciarColetas {
    background-color: rgb(234, 234, 234);
}