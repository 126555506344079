.GerenciarAdversidades-lista-container {
  justify-content: center;
  background-color: #fff;
  margin: 90px;
  margin-bottom: 20px;
  margin-top: 20px;
  /* border:2.5px solid #1976D2; */
}

.GerenciarAdversidades-coluna {
  flex: 1;
  padding: 10px;
  border-radius: 12px;
  background-color: #ffffff;
  justify-content: center;
  margin: 10px;
}

.GerenciarAdversidades-lista-itens {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.GerenciarAdversidades-item {
  font-size: 11px;
  padding: 5px;
  background-color: #fff;
  color: #555;
  margin-bottom: 5px;
}

.GerenciarAdversidades-item-total {
  font-size: 11px;
  padding: 5px;
  background-color: #fff;
  color: #1364B5;
  margin-bottom: 5px;
}

.GerenciarAdversidades-form-group {
  margin-bottom: 30px;
  width: 100%;
}

.GerenciarAdversidades-form-row {
  width: 85%;

  display: flex;
  justify-content: center;
  gap:10px;
}
