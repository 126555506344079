.container-AlterarPerfil {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin:100px;
  margin-top:0;
  margin-bottom:10px;
  height: 60vh;
}

.button-container-AlterarPerfil {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.css-b0140l-MuiPaper-root-MuiAlert-root{
  margin:100px;
  margin-top:10px;
}

.css-wnkddu-MuiPaper-root-MuiAlert-root {
  margin:100px;
  margin-top:10px;
}