.ModalInfo-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.ModalInfo-modal-content {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 70vh;
}

.ModalInfo-imoveis {
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 70vh;
}

.ModalInfo-box {
    overflow-y: scroll;
    padding: 10px;
    flex: 1;
}


.ModalInfo-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.ModalInfo-row-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 6px 8px;
    gap: 5px;
}

.css-bqzk6i-MuiTabs-root .css-1taujrx-MuiButtonBase-root-MuiTab-root {
    min-height: 40px;
}

.ModalInfo-text {
    color: #3f50b5;
    font-weight: bold;
    font-size: 12px;
}

.ModalInfo-linha {
    border-bottom: 1px solid #e9ecef;
    border-top: 1px solid #e9ecef;
    width: calc(100% + 20px);
    margin-left: -10px;

}

.ModalInfo-linhaAzul {
    height: 2px;
    background-color: #1976D2;
    width: 100%;
}

.ModalInfo-form-group {
    margin-bottom: 5px;
}

.ModalInfo-row-column {
    width: 40%;
}

.ModalInfo-label {
    display: block;
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 12px;
}

.ModalInfo-label-text {
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
}

.ModalInfo-input {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
    font-size: 12px;
}

.ModalInfo-form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px
}

.ModalInfo-content {
    width: 100%;
}

.disabled {
    background-color: rgb(234, 234, 234);
}

.ModalInfo-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.ModalInfo-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;

    border-radius: 6px;

}

.ModalInfo-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555;

}

.ModalInfo-scrollbar::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 6px;
}