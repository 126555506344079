.container-ExportarDados {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin:100px;
    margin-top:0;
    height: 80vh;
}

.a-ExportarDados {
    float: left;
    cursor: pointer;
    font-size: 15px;
    line-height: 5px;
    border: black;
}




  