.ModalCadastro-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.ModalCadastro-modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.ModalCadastro-close-bnt {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: end;
    width: 100%;
}
.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.2rem;
}

.ModalCadastro-form-group {
    margin-bottom: 10px;
}

.ModalCadastro-form-row {
    display: flex;
    justify-content: center;
    gap:10px;
}

.ModalCadastro-label {
    display: block;
    margin-bottom: 5px;
}

.ModalCadastro-input[type='text'] {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.ModalCadastro-select {
  width: 140px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  appearance: none;
  background-size: 16px;
}

.ModalCadastro-select option {
  padding: 5px;
}

