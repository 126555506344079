.modalEdificacao {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /*deixa acima de todos os elementos*/
}

.modalEdificacao-content {
    position: relative;
    background-color: white;
    padding: 30px;
    border-radius: 5px;
}
.modalEdificacao-close-bnt {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: end;
    width: 100%;
}
.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.2rem;
}

.modalEdificacao-titulo {
    text-align: center;
    margin-bottom: 20px;
}

.modalEdificacao-form-group {
    margin-bottom: 10px;
}

.form-row {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.modalEdificacao-label {
    display: block;
    margin-bottom: 5px;
}

.modalEdificacao-input[type='text'] {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.modalEdificacao-select {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc; 
}


.modalEdificacao-green-bnt {
    height: 40px;
    width: 50px;
    background-color: #4caf50;
    color: white;
    border: none;
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 5px;
    align-self: center;
}

.modalEdificacao-green-bnt:hover {
    background-color: #349c37;
}

.modalEdificacao-red-bnt {
    height: 40px;
    width: 50px;
    background-color: #f00;
    color: white;
    border: none;
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 5px;
    align-self: center;
}

.modalEdificacao-red-bnt:hover {
    background-color: #cc0000;
}