.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.modal-close-bnt {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: end;
    width: 100%;
}
.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.2rem;
}

.form-group {
    margin-bottom: 5px;
}   

.form-row-Logradouro {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
}

.ModalAlterarLogradouro-label {
    width: 100%;
    display: block;
    margin-bottom: 5px;
}

.ModalAlterarLogradouro-input[type='text'] {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.ModalAlterarLogradouro-fixed {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    background-color:#d8d8d8;
    border: 1px solid #ccc;
}

.ModalAlterarLogradouro-select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  appearance: none;
  background-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;

}

.ModalAlterarLogradouro-select option {
  padding: 5px;
}


.container-bnt {
    display: flex;
    justify-content: center;
}

.ModalAlterarLogradouro-green-bnt {
    height: 40px;
    width: 50px;
    background-color: #4caf50;
    color: white;
    border: none;
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 5px;
    align-self: center;
}

.ModalAlterarLogradouro-green-bnt:hover {
    background-color: #349c37;
}

.ModalAlterarLogradouro-red-bnt {
    height: 40px;
    width: 50px;
    background-color: #f00;
    color: white;
    border: none;
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 5px;
    align-self: center;
}

.ModalAlterarLogradouro-red-bnt:hover {
    background-color: #cc0000;
}

