.sidebar {
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: 250px;
    padding: 20px;
    height: 100%;
    left: -300px;
    top: 65px;
    transition: all 1s ease;
    z-index: 4;
    border-top: 2px solid #1976D2;
}

.sidebar.show {
    left: 0;
}

* {
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sidebar ul li {
    color: rgb(0, 0, 0);
    font-size: small;
    position: relative;
    padding: 13px;
    display: block;
    cursor: pointer;
    text-align: start;
}

.sidebar ul li:hover,
.sidebar ul li.active {
    background:  rgb(206, 206, 206);
}

.sidebar ul li:hover:before,
.sidebar ul li.active:before {
    display: block;
}

.container {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

.logo-sidebar {
    width: 110%;
    margin-top: 10px;
    margin-left: -10px;
}
.text-container {
    /* margin-left: 20px; */
    background: rgb(255, 255, 255);
    font-size: small;
    
    color: rgb(0, 0, 0, 0.7);
    text-align: justify;
}
.icone {
    transition: transform 0.3s ease;
    margin-left: 5px;
  }

.rotacionar {
    transform: rotate(90deg);
  }
.li-principal{
    margin-left: -20px;
    font-weight: bold;
}
.li-bold{
    font-weight: bold;
}