.ModalMesmoLote-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.ModalMesmoLote-modal-content {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:5px;
}

.ModalMesmoLote-form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ModalMesmoLote-form-row {
    display: flex;
    justify-content: start;
    gap: 10px;
}

.ModalMesmoLote-label {
    display: block;
    margin-bottom: 5px;
}

.ModalMesmoLote-fixed {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    background-color:#d8d8d8;
    border: 1px solid #ccc;
}

.ModalMesmoLote-input[type='text'] {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.ModalMesmoLote-select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  appearance: none;
  background-size: 16px;
}

.ModalMesmoLote-select option {
  padding: 5px;
}


.ModalMesmoLote-mapa {
    padding: 15px;
    width: 650px;
    height: 500px;
    background-Color: #AAD3DF;
}

.ModalMesmoLote-close-bnt {
display: flex;
justify-content: end;
width: 100%;
}

