.about-button-About {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.camada-btn-About {
  background-color: rgb(19, 100, 181);
  border: none;
  padding: 7px;
  border-radius: 15px;
  font-size: 15px;
  color: rgb(255, 255, 255);
}

.camada-btn-About:hover {
  background-color: rgb(19, 51, 181);
  cursor: pointer;
}

.float-container-About {
  position: absolute;
  bottom: 100%;
  margin-bottom: 5px;
  left: 0px;
  padding: 5px;
  text-align:left;
  white-space: nowrap;
  background: rgb(249, 249, 249);
  font-size: small;
  font-weight: bold;
  color: rgb(0, 0, 0, 0.7);
  text-align:start;
  width: 265px;
  border-radius: 5px;
}

.toggle-About {
  display: inline-block;
  padding: 4px;
}

.toggle-label-About {
  margin-left: 0px;
  position: relative;
}
