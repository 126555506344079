.main-container-gerenciarUsuarios {
    display: flex;
    width: 100%;
}

.container-gerenciarUsuarios {
    flex: 1;
    flex-direction: column;
}

.conteudo-gerenciarUsuarios {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.input-gerenciarUsuarios {
    width: 100%;
}

.conteudo-lista-gerenciarUsuarios {
    background-color: rgb(255, 255, 255);
    margin: 5px;
    border-radius: 10px;
    box-shadow: 0 5px rgba(216, 214, 214, 0.811);
    padding: 0;
    height: 450px;
}

.barra-direita-gerenciarUsuarios {
    display: flex;
    align-items: start;
    padding: 5px;
    flex-direction: column;
    gap: 20px;
}