.modal-Eixo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-Eixo {
    position: relative;
    width: auto;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.modal-close-bnt {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: end;
    width: 100%;
}
.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.2rem;
}


.form-group-Eixo {
    margin-bottom: 5px;
}

.form-row-Eixo {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
}

.ModalAlterarEixo-label {
    display: block;
    margin-bottom: 5px;
}

.ModalAlterarEixo-input[type='text'] {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.ModalAlterarEixo-fixed {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    background-color:#d8d8d8;
    border: 1px solid #ccc;
}

.ModalAlterarEixo-select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  appearance: none;
  background-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;

}

.ModalAlterarEixo-select option {
  padding: 5px;
}


