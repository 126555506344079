.ImprimirBic-main-container {
    display: flex;
    width: 100%;
}

.ImprimirBic-container-imovel {
    flex: 1;
    flex-direction: column;
}

.ImprimirBic-conteudo-imovel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.ImprimirBic-input-imovel {
    width: 100%;
}

.ImprimirBic-conteudo-lista {
    background-color: rgb(255, 255, 255);
    margin: 5px;
    border-radius: 10px;
    box-shadow: 0 5px rgba(216, 214, 214, 0.811);
    padding: 30px;
}

.ImprimirBic-barra-direita {
    display: flex;
    align-items: start;
    padding: 5px;
    flex-direction: column;
    gap: 20px;
}

.ImprimirBic-btn-imovel {
    background-color: rgb(19, 100, 181);
    border: none;
    padding: 15px;
    border-radius: 25px;
    font-size: large;
    color: white;
}

.ImprimirBic-btn-imovel:hover {
    background-color: rgb(19, 51, 181);
    cursor: pointer;
}
.ImprimirBic-linha{
    height: 2.5px;
    background-color: #1976D2;
    width: 100%;
}
.ImprimirBic-desabilitado{
    background-color: rgb(234, 234, 234);
}



