/* Base CSS */
.App {
  position: relative;
}

.container-AboutButton {
  position: absolute;
  left: 2%;
  bottom: 2%;
}

.floating-button-mapa {
  cursor: pointer;
  position: relative;
  bottom: 3.5vh;
  left: 2%;
  z-index: 5;
}

.row-button-satelite {
  display: flex;
  flex-direction: row;
  padding: 2%;
  border-radius: 7.5px;
  margin: 0;
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.3);
}

.div-button-satelite {
  text-align: center;
}

.div-button-satelite:hover .botao {
  border: 2px solid #000000;
}

.div-button-satelite:hover {
  font-weight: bold;
}

.botao {
  width: 5vw;
  height: 5vw;
  margin-right: 0.5vw;
  border-radius: 15px;
  border: 1px solid #000000;
}

.label-satelite {
  display: block;
  font-size: 1vw;
  margin-top: -6px;
  margin-bottom: -4px;
}

.logo-OSM {
  color: #333333;
  text-shadow: 0 0 2px white;
  font-size: 12px;
  bottom: 0;
  right: 0;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.75);
  margin: 0;
  padding: 0.05rem 0.5em;
  border-top-left-radius: 0.25rem;
  text-decoration: none;
}

/* Media queries para tornar o layout responsivo */
@media (max-width: 768px) {
  .container-AboutButton {
    left: 5%;
    bottom: 5%;
  }

  .floating-button-mapa {
    left: 5%;
  }


  .label-satelite {
    display: block;
    margin-top: -6px;
    margin-bottom: 0px;
  }

  .row-button-satelite {
    flex-direction: column;
    padding: 2% 0;
  }

  .botao {
    width: 18vw;
    height: 18vw;
    margin-bottom: 10px;
  }
}