* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

.container {
    width: 100%;
    margin: 0 auto;
}
 
.container-login {
    width: 100%;
    min-height: 89vh;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    padding: 15px;

    background-color: #ffffff;
 
}

.wrap-login { 
    width: 390px;
    background-color: rgb(0, 22, 49);
    border-radius: 10px;
    
    overflow: hidden;
    padding: 70px 55px 30px 55px;

    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.close {
position: relative;
top: -50px;
left:290px;
}

.close-icon {
    background-color: rgb(0, 22, 49);
    border: none;
    color: rgb(236, 236, 236);
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.login-form{
    width: 100%;
}

.login-form-title {
    display: block;
    font-size: 30px;
    color:azure;

    line-height: 0.5;
    text-align: center;

    margin-bottom: 90px;
    margin-top: 0px;
}

.wrap-input {

    width: 100%;
    position: relative;
    border-bottom: 2px solid #dadada;
    margin-bottom: 37px;
}

.input {
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    border: none;
    display: block;

    width: 100%;
    height: 40px;

    background-color: transparent;

    padding: 0 5px;
}

.focus-input {
    position: absolute;
    display: block; 
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    pointer-events: none;
    color: #dadada
}

.focus-input::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2spx;
    left: 0;
    width: 0;
    height: 2px;

}

.focus-input::after {
    font-size: 15px;
    color: #999;
    line-height: 1.2;
    
    content: attr(data-placeholder);

    display: block;
    width: 100%;
    position: absolute;
    top: 16px;
    left: 0px;

    padding-left: 5px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

.input:focus {
    outline: 0;
}

.has-val + .focus-input::after {
    top: -20px
}

.input:focus + .focus-input::after {
    top: -20px;
}

.input:focus + .focus-input::before {
    width: 100%;
}

.container-login-form-btn {
    display: flex;
    flex-wrap:  wrap;
    justify-content: center;
    padding-bottom: 13px;
}

.login-form-btn {
    margin-top: 30px;
    font-size: 15px;
    border-radius: 5px;
    color: #000000;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 40px;
}

.login-form-btn:hover {
    cursor: pointer;
}
