.header {
    background-color: white;
    padding: 0.5vh;
    height: 9vh;
    border-bottom: 2px solid #1976D2;
}

.header a {
    float: left;
    color: rgb(19, 100, 181);
    text-align: center;
    padding: 1vh;
    text-decoration: none;
    font-size: 15px;
    line-height: 25px;
}

.header a.logo {
    max-width: 175px;
    height: 0;
}


.header a:hover {
    color: #03202d;
}

.header-right {
    float: right;
}

.header-center {
    position: absolute;
    right: 100px;
    z-index: 2500;
}

.header a.btn-Login {
    font-size: 20px;
    color: rgb(19, 100, 181);
}


input {
    margin-right: 5px;
}

select {
    border-radius: 0.25rem;
    font-size: 0.9rem;
    padding: 0.4rem 0.6rem;
}


.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.input-group .form-control {
    margin-right: 5px;

}

.form-control .MuiInputBase-input {
    font-size: 0.7rem;

}

.form-control .MuiInputLabel-root {
    font-size: 0.7rem;
}

.search-btn {
    border: 1px solid;
    border-radius: 0.25rem;
    color: #fff;
    background-color: rgb(19, 100, 181);
    padding: 5px;
    font-size: medium;
}

.search-btn:hover {
    background-color: rgb(19, 51, 181);
    cursor: pointer;
}


.label-busca {
    position: absolute;
    top: -13px;
    color: rgb(19, 100, 181);
    font-size: small;
}





/* AVATAR CSS */
.container-avatar {
    margin-right: 15px;
    right: -10px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.container-name {
    float: left;
    color: rgb(19, 100, 181);
    text-align: center;
    padding: 10px;
    text-decoration: none;
    font-size: 20px;
    line-height: 25px;
}

.avatar {
    cursor: pointer;
}

.avatar:hover {
    opacity: 0.5;
    cursor: pointer;
}

.container-BoxLogout {
    position: absolute;
    padding: 1px;
    top: 70px;
    right: 10px;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.2);
    z-index: 1500;
}

.btn-BoxLogout {
    border: none;
    height: auto;
    cursor: pointer;
    background: none;
    color: rgb(3, 3, 3);
    margin: 10px;
}

.sidebar-icon {
    transition: transform 0.3s ease, opacity 0.3s ease;
    width: 18px;
}

.sidebar-icon.open {
    transform: rotate(90deg);
}

.sidebar-icon.closed {
    transform: rotate(0deg);
}