.modalEditarUsuario {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

}

.modalEditarUsuario-content {
    position: relative;
    background-color: white;
    padding: 30px;
    border-radius: 5px;
}
.modalEditarUsuario-close-bnt {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: end;
    width: 100%;
}
.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.2rem;
}

.modalEditarUsuario-titulo {
    text-align: center;
    margin-bottom: 20px;
}

.modalEditarUsuario-form-group {
    margin-bottom: 10px;
}

.modalEditarUsuario-form-row {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.modalEditarUsuario-label {
    display: block;
    margin-bottom: 5px;
}

.modalEditarUsuario-input[type='text'] {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.modalEditarUsuario-input[type='email'] {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.modalEditarUsuario-input[type='password'] {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.modalEditarUsuario-input[type='date'] {
    width: 179px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.modalEditarUsuario-select {
    width: 179px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.modalEditarUsuario-btn-password {
    height: 27.6px;
    margin-top: 27px;
    margin-left: -30px;
    margin-right: 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

