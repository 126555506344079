.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1004;
}

.float-container {
  background-color: white;
  padding: 15px 10px;
  
  height: 92.1vh;
  width: 51vh;
  position: absolute;
  bottom: -40%;
 
  right: -150rem;
  transition: right 0.3s ease;
}


.mostrar {
  right: -20px;
}

.float-container-ul {
  overflow-y: auto;
  height: 100%;
}

.float-border {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.float-button-unselect {
  background-color: transparent;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem 1rem;
  margin-bottom: -1px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.float-button-unselect:hover,
.toggle:hover {
  background-color: #dddddd57;
}

.toggle {
  background-color: transparent;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem 1rem;
  margin-bottom: -1px;
  border: none;
  border-top: 1px solid #ddd;
  font-size: 1rem;
  cursor: pointer;
}

.toggle input[type="checkbox"] {
  width: 0;
  height: 0;
}

.toggle label {
  cursor: pointer;
  margin-left: 0.5rem;
  width: 100%;
}

.toggle.checked {
  background-color: #428bca;
  color: white;
  border-bottom: 0;
}

/* .toggle.checked.float-border-first {
  background-color: #428bca;
  color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
} */

.toggle.checked.float-border-last {
  background-color: #428bca;
  color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* .float-border-first {
  border-top: 0;
} */