.ModalSemRegistro-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.ModalSemRegistro-modal-content {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
}

.ModalSemRegistro-form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
}

.ModalSemRegistro-form-row {
    display: flex;
    justify-content: start;
    gap: 10px;
}

.ModalSemRegistro-label {
    display: block;
    margin-bottom: 5px;
}

.ModalSemRegistro-fixed {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    background-color:#d8d8d8;
    border: 1px solid #ccc;
}

.ModalSemRegistro-input[type='text'] {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.ModalSemRegistro-select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  appearance: none;
  background-size: 16px;
}

.ModalSemRegistro-select option {
  padding: 5px;
}

.ModalSemRegistro-mapa {
    padding: 15px;
    width: 650px;
    height: 500px;
    background-Color: #AAD3DF;
}

.ModalSemRegistro-close-bnt {
    display: flex;
    justify-content: end;
    width: 100%;
}

.container-formControlLabel-ModalSemRegistro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 320;
    margin-left: -45; 
}