.barra-direita-alterarEixo {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
  position: fixed; 
  right: 5px; 
}

.conteudo-lista-alterarEixo {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 5px rgba(216, 214, 214, 0.811);
  padding: 0;
  height: 550px;
}