.modalPontoInteresse {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /*deixa acima de todos os elementos*/
}

.modalPontoInteresse-content {
    position: relative;
    background-color: white;
    padding: 30px;
    border-radius: 5px;
}
.modalPontoInteresse-close-bnt {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: end;
    width: 100%;
}
.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.2rem;
}

.modalPontoInteresse-titulo {
    text-align: center;
    margin-bottom: 20px;
}

.modalPontoInteresse-form-group {
    margin-bottom: 10px;
}

.form-row {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.modalPontoInteresse-label {
    display: block;
    margin-bottom: 5px;
}

.modalPontoInteresse-input[type='text'] {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.modalPontoInteresse-select {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc; 
}
